import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e2bca00e = () => interopDefault(import('../pages/activiteiten.vue' /* webpackChunkName: "pages/activiteiten" */))
const _39185d04 = () => interopDefault(import('../pages/magazine.vue' /* webpackChunkName: "pages/magazine" */))
const _05c02508 = () => interopDefault(import('../pages/programmas/index.vue' /* webpackChunkName: "pages/programmas/index" */))
const _6baafeb9 = () => interopDefault(import('../pages/royals/index.vue' /* webpackChunkName: "pages/royals/index" */))
const _6de30d5c = () => interopDefault(import('../pages/royaltynieuws/index.vue' /* webpackChunkName: "pages/royaltynieuws/index" */))
const _32676224 = () => interopDefault(import('../pages/zoeken.vue' /* webpackChunkName: "pages/zoeken" */))
const _efccc946 = () => interopDefault(import('../pages/rubriek/fotoboeken.vue' /* webpackChunkName: "pages/rubriek/fotoboeken" */))
const _72388e26 = () => interopDefault(import('../pages/rubriek/link-in-bio.vue' /* webpackChunkName: "pages/rubriek/link-in-bio" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2c20294e = () => interopDefault(import('../pages/activiteit/_slug/index.vue' /* webpackChunkName: "pages/activiteit/_slug/index" */))
const _01bf4077 = () => interopDefault(import('../pages/alles-over/_slug.vue' /* webpackChunkName: "pages/alles-over/_slug" */))
const _040983c0 = () => interopDefault(import('../pages/programmas/_slug.vue' /* webpackChunkName: "pages/programmas/_slug" */))
const _abd78b7a = () => interopDefault(import('../pages/programmas/_slug/index.vue' /* webpackChunkName: "pages/programmas/_slug/index" */))
const _6b0fbb76 = () => interopDefault(import('../pages/programmas/_slug/afleveringen.vue' /* webpackChunkName: "pages/programmas/_slug/afleveringen" */))
const _c7acca7c = () => interopDefault(import('../pages/programmas/_slug/extras.vue' /* webpackChunkName: "pages/programmas/_slug/extras" */))
const _371b231a = () => interopDefault(import('../pages/programmas/_slug/over.vue' /* webpackChunkName: "pages/programmas/_slug/over" */))
const _69f45d71 = () => interopDefault(import('../pages/royals/_slug.vue' /* webpackChunkName: "pages/royals/_slug" */))
const _6c2c6c14 = () => interopDefault(import('../pages/royaltynieuws/_slug.vue' /* webpackChunkName: "pages/royaltynieuws/_slug" */))
const _6c38c4dd = () => interopDefault(import('../pages/rubriek/_slug.vue' /* webpackChunkName: "pages/rubriek/_slug" */))
const _f694d7da = () => interopDefault(import('../pages/activiteit/_slug/line-up/index.vue' /* webpackChunkName: "pages/activiteit/_slug/line-up/index" */))
const _41d563a0 = () => interopDefault(import('../pages/activiteit/_slug/livestream.vue' /* webpackChunkName: "pages/activiteit/_slug/livestream" */))
const _1f8c970a = () => interopDefault(import('../pages/activiteit/_slug/nieuws.vue' /* webpackChunkName: "pages/activiteit/_slug/nieuws" */))
const _f458c716 = () => interopDefault(import('../pages/activiteit/_slug/line-up/_person.vue' /* webpackChunkName: "pages/activiteit/_slug/line-up/_person" */))
const _20a716af = () => interopDefault(import('../pages/activiteit/_slug/_field.vue' /* webpackChunkName: "pages/activiteit/_slug/_field" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/activiteiten",
    component: _e2bca00e,
    name: "activiteiten"
  }, {
    path: "/magazine",
    component: _39185d04,
    name: "magazine"
  }, {
    path: "/programmas",
    component: _05c02508,
    name: "programmas"
  }, {
    path: "/royals",
    component: _6baafeb9,
    name: "royals"
  }, {
    path: "/royaltynieuws",
    component: _6de30d5c,
    name: "royaltynieuws"
  }, {
    path: "/zoeken",
    component: _32676224,
    name: "zoeken"
  }, {
    path: "/rubriek/fotoboeken",
    component: _efccc946,
    name: "rubriek-fotoboeken"
  }, {
    path: "/rubriek/link-in-bio",
    component: _72388e26,
    name: "rubriek-link-in-bio"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/activiteit/:slug",
    component: _2c20294e,
    name: "activiteit-slug"
  }, {
    path: "/alles-over/:slug?",
    component: _01bf4077,
    name: "alles-over-slug"
  }, {
    path: "/programmas/:slug",
    component: _040983c0,
    children: [{
      path: "",
      component: _abd78b7a,
      name: "programmas-slug"
    }, {
      path: "afleveringen",
      component: _6b0fbb76,
      name: "programmas-slug-afleveringen"
    }, {
      path: "extras",
      component: _c7acca7c,
      name: "programmas-slug-extras"
    }, {
      path: "over",
      component: _371b231a,
      name: "programmas-slug-over"
    }]
  }, {
    path: "/royals/:slug",
    component: _69f45d71,
    name: "royals-slug"
  }, {
    path: "/royaltynieuws/:slug",
    component: _6c2c6c14,
    name: "royaltynieuws-slug"
  }, {
    path: "/rubriek/:slug?",
    component: _6c38c4dd,
    name: "rubriek-slug"
  }, {
    path: "/activiteit/:slug?/line-up",
    component: _f694d7da,
    name: "activiteit-slug-line-up"
  }, {
    path: "/activiteit/:slug?/livestream",
    component: _41d563a0,
    name: "activiteit-slug-livestream"
  }, {
    path: "/activiteit/:slug?/nieuws",
    component: _1f8c970a,
    name: "activiteit-slug-nieuws"
  }, {
    path: "/activiteit/:slug?/line-up/:person?",
    component: _f458c716,
    name: "activiteit-slug-line-up-person"
  }, {
    path: "/activiteit/:slug?/:field",
    component: _20a716af,
    name: "activiteit-slug-field"
  }, {
    path: "/*",
    component: _2efaf336,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
